<template>
  <!--
1- Recieve an ID
2- Collect the ID and preform AXIOS request to recover data for that ID
3- insert the data in input boxes
4- on submit update the database with new data for that ID -->

  <vx-card>
    <h1>تعديل مسابقة</h1>
    <div class="mb-5">&#8203;</div>
    <h4 class="mt-8">صورة المسابقة</h4>
    <div class="vx-row bottom-seperator">
        <div class="vx-col my-1 w-full mb-6">
          <div class="con-img-upload flex" id="images">
              <div class="img-upload">
                <button type="button" class="btn-x-file" @click="spliceFile()">
                <i translate="translate" class="material-icons notranslate"
                    >clear</i
                    >
                </button>
                <img
                    v-if="media.length"
                    :src="media[0].blob"
                    style="max-width: none; max-height: 100%"
                    />
                <img v-else :src="contest.media" style="width: 100%" />
              </div>
          </div>
          <file-upload
              ref="upload"
              :multiple="false"
              v-model="media"
              class="vs-button bg-primary mt-5"
              post-action="/post.method"
              put-action="/put.method"
              extensions="jpg,gif,png,webp"
              @input-filter="inputFilter"
              @input-file="inputFile"
              >
              تغيير صورة
          </file-upload>
        </div>
    </div>
    <div class="vx-row">
        <div class="vx-col my-3 sm:w-1/2 w-full">
          <vs-input
              class="w-full"
              label-placeholder="إسم المسابقة"
              v-model="contest.name"
              />
          <div class="vx-row mt-8">
              <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                <label for="contest">الشركة</label>
                <select
                    v-model="contest.brand_id"
                    class="w-full h-12"
                    name="brand"
                    v-validate="'required'"
                    >
                    <option
                      v-for="brand in brands"
                      :key="brand.id"
                      :value="brand.id"
                      >
                      {{ brand.name }}
                    </option>
                </select>
                <div class="description-text">لأي شركة تتبع هذه المسابقة</div>
              </div>
              <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                <label for="contest">نوع المسابقة</label>
                <select
                    v-model="contest.contestType_id"
                    class="w-full h-12"
                    name="brand"
                    v-validate="'required'"
                    >
                    <option
                      v-for="type in contestTypes"
                      :key="type.value"
                      :value="type.value"
                      >
                      {{ type.label }}
                    </option>
                </select>
              </div>
          </div>
          <div class="vx-row mt-8">
              <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                <label for="contest">تاريخ بدأ المسابقة</label>
                <vs-input type="date" class="w-full" v-model="contest.startDate" />
              </div>
              <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                <label for="contest">تاريخ إنتهاء المسابقة</label>
                <vs-input type="date" class="w-full" v-model="contest.endDate" />
                <div class="description-text text-sm mt-2">عند الوصول لتاريخ الإنتهاء سيتم إلغاء تفعيل المسابقة</div>
              </div>
          </div>
        </div>
        <div class="vx-col my-3 sm:w-1/2 w-full seperator">
          <vx-input-group v-if="contest.isMCQ" class="vx-col my-3 w-full mt-12 mb-6">
              <label for="contests">خيارات الإجابات للمسابقة</label>
              <div class="vx-row">
                <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                    <vs-input
                      color="danger"
                      class="w-full"
                      label-placeholder="إجابة 1"
                      v-model="contest.answers[0].answer"
                      />
                </div>
                <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                    <vs-input
                      color="danger"
                      class="w-full"
                      label-placeholder="إجابة 2"
                      v-model="contest.answers[1].answer"
                      />
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                    <vs-input
                      color="danger"
                      class="w-full"
                      label-placeholder="إجابة 3"
                      v-model="contest.answers[2].answer"
                      />
                </div>
                <div class="vx-col my-3 sm:w-1/2 w-full mb-2">
                    <vs-input
                      color="success"
                      class="w-full"
                      label-placeholder="الإجابة الصحيحة"
                      v-model="contest.answers[3].answer"
                      />
                </div>
              </div>
          </vx-input-group>
          <vs-checkbox class="mt-5 pr-10 text-lg mb-10 mt-10" v-model="contest.isActive">
              هل المسابقة فعالة
          </vs-checkbox>
        </div>
    </div>
    <div class="cr-contest-align mr-20 mb-10">
        <vs-button class="mr-5 text-lg py-2 " @click="$router.go(-1)" color="warning" type="border">إلغاء</vs-button>
        <vs-button class="mr-5 text-lg py-2 cr-contest-btn" color="success" @click="submit">تعديل المسابقة</vs-button>
    </div>
  </vx-card>


</template>


<script>
import moduleContest from "@/store/contests/moduleContest.js";
import vSelect from "vue-select";
import VueUploadComponent from "vue-upload-component";
import moduleProduct from "@/store/products/moduleProduct.js";
export default {
  components: {
    vSelect,
    "file-upload": VueUploadComponent,
  },
  data() {
    return {
      id: this.$route.params.id,
      contestTypes: [
        {
          label: "يومية",
          value: "1",
        },

        {
          label: "شهرية",
          value: "2",
        },

        {
          label: "رمضانية",
          value: "3",
        },

        {
          label: "عيد الفطر",
          value: "4",
        },

        {
          label: "مخصصة",
          value: "5",
        },
      ],
      media:[],
      contest: {
        media: [],
        name: "",
        answers: [
          { id: "", answer: "", correct: false },

          { id: "", answer: "", correct: false },

          { id: "", answer: "", correct: false },

          { id: "", answer: "", correct: true },
        ],
        brand_id: "",
        contestType_id: "",
        startDate: "",
        endDate: "",
        isActive: "",
        isMCQ: ""
      },
    };
  },
  computed: {
    brands() {
      return this.$store.state.product.brands;
    },
  },
  methods: {
    changeStyle(e) {
      var btnStyle = e.target.style;
      btnStyle.color = "white";
      btnStyle.backgroundColor = "black";
    },
    getContest(id) {
      //fills up data
      this.$store.dispatch("contest/getContest", id)
      .then((response) => {
        //settings up references
        var contest = response.data;
        var answersResponse = contest.answers;

        // getting all answers
        for (let i = 0; i < answersResponse.length; i++) {
          this.contest.answers[i].id = answersResponse[i].id;
          this.contest.answers[i].answer = answersResponse[i].answer;
        }
        //filling incoming ids into fields
        this.contest.name = contest.name;
        this.contest.brand_id = contest.brand_id;
        this.contest.contestType_id = contest.contest_type_id;
        this.contest.startDate = contest.start_date;
        this.contest.endDate = contest.end_date;
        this.contest.media = contest.full_path;
        this.contest.isActive = contest.is_active;
        this.contest.isMCQ = contest.mcq;
      });
    },
    inputFile(){
      this.contest.media = this.media
    },
    inputFilter(newFile, oldFile, prevent) {
      if((newFile.size/1024.0)>5000){
            this.$vs.notify({
              title: "حدث خطأ",
              text: "يجب أن لا يكون حجم الصورة أكبر من 5mb",
              color: "danger",
            });
            return prevent();
          }
      if (newFile && !oldFile) {
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          this.$vs.notify({
                title: "حدث خطأ",
                text: "يجب أن يكون الملف بإمتداد صورة صحيح",
                color: "danger",
              });
          return prevent();
        }
      }
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    spliceFile() {
      this.contest.media = ''
    },
    submit() {
      var answers = this.contest.answers;
      // REQUEST HERE
      this.$validator.validateAll().then((result) => {
        if (result) {
          var bodyFormData = new FormData();

          bodyFormData.set("id", this.id);
          bodyFormData.set("name", this.contest.name);
          bodyFormData.set("answers", JSON.stringify(answers));
          bodyFormData.set("brand", this.contest.brand_id);
          bodyFormData.set("start_date", this.contest.startDate);
          bodyFormData.set("end_date", this.contest.endDate);
          bodyFormData.set("type", this.contest.contestType_id);
          bodyFormData.set("is_active", this.contest.isActive?1:0);
          bodyFormData.set("mcq", this.contest.isMCQ?1:0);

          if(typeof this.contest.media === 'object'){
            bodyFormData.set("question", this.contest.media[0].file);
          }
          this.$store
            .dispatch("contest/updateContest", bodyFormData)
            .then(() => {
              this.$vs.notify({
                title: "تم بنجاح",
                text: "تم اضافة المسابقة بنجاح",
                color: "success",
              });
              this.$router.push({ name: "contests" });
            })
            .catch(() => {
              this.$vs.notify({
                title: "حدث خطأ",
                text: "يرجى اعادة المحاولة",
                color: "danger",
              });
            });
        }
      });
    },
  },
  created() {
    if (!moduleContest.isRegistered) {
      this.$store.registerModule("contest", moduleContest);
      moduleContest.isRegistered = true;
    }
     if (!moduleProduct.isRegistered) {
      this.$store.registerModule("product", moduleProduct);
      moduleProduct.isRegistered = true;
    }
    this.$store.dispatch("product/fetchBrands");
    this.getContest(this.id);
  },
};
</script>

<style>
.cr-contest-btn {
  font-family: "Cairo", sans-serif;
}
.seperator{
  border-left: 1px solid rgb(204, 204, 204);

}
.bottom-seperator{
  border-bottom: 1px solid rgb(204, 204, 204);
  margin-bottom: 4em;
}

</style>
